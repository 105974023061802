import React from "react";
import type { StoresDataProps } from "../SearchState";
import { Store } from "./Store";
import { StyledList } from "./Stores.styled";

type StoreListProps = {
  stores: StoresDataProps[];
};

export const StoresList: React.FunctionComponent<StoreListProps> = ({
  stores,
}) => {
  return (
    <div className="container">
      <StyledList>
        {stores.map((store) => (
          <Store
            store={store}
            key={store.id}
            showOpeningHours={store.openingHours.length > 0}
          />
        ))}
      </StyledList>
    </div>
  );
};
