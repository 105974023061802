import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../styles/xxl-theme";
import { MQ, typographyToCss } from "../../../styles/helpers";

const { colors, spaces } = xxlTheme;

const HeadingTypography = {
  fontFamily: "var(--font-family-bold)",
  fontSize: 20,
  lineHeight: 1.4,
  letterSpacing: -0.15,
  laptop: {
    fontSize: 26,
  },
};

export const FaqListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
`;

export const FaqItemWrapper = styled.li`
  border-bottom: 1px solid ${colors.xxlLightGrey};
  margin-top: ${spaces.large};
  letter-spacing: -0.15px;

  ${MQ("smallTablet")} {
    margin-top: ${spaces.big};
  }
`;

export const Title = styled.h3`
  ${typographyToCss(HeadingTypography)};
  display: inline-block;
  position: relative;
  margin: 0 0 ${spaces.smallRegular};
  color: ${colors.xxlWebBlack};
`;
export const Preamble = styled.p`
  margin: 0;
  color: ${colors.xxlWebBlack};
  width: 100%;

  ${MQ("laptop")} {
    width: 70%;
  }
`;

export const Link = styled.a`
  padding: 0 0 ${spaces.large};
  display: block;

  &:hover ${Title} {
    &:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${colors.xxlWebBlack};
      content: "";
    }
  }

  ${MQ("smallTablet")} {
    padding: 0 0 ${spaces.big};
  }
`;
