import styled from "@emotion/styled/macro";
import { MQ } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";

const { spaces } = xxlTheme;

export const GuidesList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: ${spaces.smallRegular};
`;

export const GuideItem = styled.li`
  width: 100%;

  ${MQ("tablet")} {
    width: calc((100% - var(--space-small-regular)) / 2);
  }

  ${MQ("desktop")} {
    width: calc((100% - (2 * var(--space-small-regular))) / 3);
  }
`;

export const LoadMore = styled.button`
  width: 100%;
  display: block;
  margin: ${spaces.large} auto 0;

  ${MQ("tablet")} {
    width: 351px;
    margin-top: ${spaces.big};
  }

  ${MQ("desktop")} {
    width: 436px;
  }
`;
