import { useMediaQuery } from "@mui/material";
import { isNotEmpty } from "@xxl/common-utils";
import React, { useEffect, useState } from "react";
import { useTracking } from "../../../contexts/Tracking";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { mobileMediaQuery } from "../../../utils/xxl-screen";
import {
  GuideTeaser,
  type GuidePreviewContentDataWithTrackingTicket,
} from "../../guideCarousel/GuideTeaser";
import { GuideItem, GuidesList, LoadMore } from "./Guides.styled";

type GuidesTabProps = {
  guides: GuidePreviewContentDataWithTrackingTicket[];
};

const GUIDES_LOAD_AMOUNT = 9;
const MOBILE_GUIDES_LOAD_AMOUNT = 3;

const createGuidesArray = (
  guides: GuidePreviewContentDataWithTrackingTicket[],
  startIndex: number,
  amount: number
): GuidePreviewContentDataWithTrackingTicket[] => {
  const guidesArray: GuidePreviewContentDataWithTrackingTicket[] = [];
  guides.forEach((item, index) => {
    if (index >= startIndex && index < startIndex + amount) {
      guidesArray.push(item);
    }
  });

  return guidesArray;
};

export const GuidesTab: React.FunctionComponent<GuidesTabProps> = ({
  guides,
}) => {
  const { t } = useTranslations();
  const isMobile = useMediaQuery(mobileMediaQuery);
  const tracking = useTracking();
  const [guidesAmount, setGuidesAmount] = useState<number>(
    isMobile ? MOBILE_GUIDES_LOAD_AMOUNT : GUIDES_LOAD_AMOUNT
  );
  const initialArray = createGuidesArray(guides, 0, guidesAmount);
  const [displayedGuides, setDisplayedGuides] =
    useState<GuidePreviewContentDataWithTrackingTicket[]>(initialArray);
  const [startIndex, setStartIndex] = useState(guidesAmount);

  const handleLoadMore = () => {
    if (guides.length > startIndex + 1) {
      const guidesArray = createGuidesArray(guides, startIndex, guidesAmount);
      setDisplayedGuides([...displayedGuides, ...guidesArray]);
      setStartIndex(startIndex + guidesAmount);
    }
  };

  const handleClickTracking = (ticket?: string) => {
    isNotEmpty(ticket) && tracking.sendClickEvent({ ticket });
  };

  useEffect(() => {
    if (isMobile) {
      setGuidesAmount(MOBILE_GUIDES_LOAD_AMOUNT);
    } else {
      setGuidesAmount(GUIDES_LOAD_AMOUNT);
    }
  }, [isMobile]);

  return (
    <div className="container">
      <GuidesList>
        {displayedGuides.map((item, index) => (
          <GuideItem
            key={`${item.url as string}_${index}`}
            onClick={() => handleClickTracking(item.ticket)}
          >
            <GuideTeaser guide={item} />
          </GuideItem>
        ))}
      </GuidesList>
      {guides.length > startIndex && (
        <LoadMore
          type="button"
          onClick={handleLoadMore}
          className="button button--small button--secondary button--outlined"
        >
          {t("guide.list.view.more.guides")}
        </LoadMore>
      )}
    </div>
  );
};
