import React from "react";
import type { FaqDataProps } from "../SearchState";
import { FaqItemWrapper, Preamble, Title, Link } from "./FaqList.styled";
import { useTracking } from "../../../contexts/Tracking";
import { isNotEmpty } from "@xxl/common-utils";

type FaqItemProps = {
  item: FaqDataProps;
};
export const FaqItem: React.FunctionComponent<FaqItemProps> = ({ item }) => {
  const tracking = useTracking();
  const { title, url, preamble, ticket } = item;

  const handleClickTracking = () => {
    isNotEmpty(ticket) && tracking.sendClickEvent({ ticket });
  };

  return (
    <FaqItemWrapper onClick={handleClickTracking}>
      <Link href={url}>
        <Title>{title}</Title>
        {preamble !== undefined && preamble.length > 0 && (
          <Preamble>{preamble}</Preamble>
        )}
      </Link>
    </FaqItemWrapper>
  );
};
