import React from "react";
import type { FaqDataProps } from "../SearchState";
import { FaqItem } from "./FaqItem";
import { FaqListWrapper } from "./FaqList.styled";

type FaqListProps = {
  faqs: FaqDataProps[];
};

export const FaqList: React.FunctionComponent<FaqListProps> = ({ faqs }) => {
  return (
    <FaqListWrapper className="container">
      {faqs.map((item) => (
        <FaqItem key={item.id} item={item} />
      ))}
    </FaqListWrapper>
  );
};
