import { isEmpty, isNullOrUndefined } from "@xxl/common-utils";
import type {
  FunctionComponent,
  MouseEvent,
  PropsWithChildren,
  TouchEvent,
} from "react";
import { ContentWrapper, TabItem, TabsList, TabsNav } from "./Tabs.styled";

export type TabProps = {
  isActive: boolean;
  id: string;
  name: string;
  count: number;
};

export type TouchOrMouseEvent = TouchEvent<Element> | MouseEvent<HTMLElement>;

type TabsProps = PropsWithChildren<{
  handleTabChange: (event: TouchOrMouseEvent) => void;
  tabs: TabProps[] | null;
  tabsContainerClassName?: string;
}>;

export const Tabs: FunctionComponent<TabsProps> = ({
  children,
  handleTabChange,
  tabs,
  tabsContainerClassName = "",
}) => {
  if (isNullOrUndefined(tabs) || isEmpty(tabs)) {
    return null;
  }

  return (
    <>
      <div className={tabsContainerClassName}>
        <TabsNav className="hide-scrollbars">
          <ContentWrapper>
            <TabsList>
              {tabs.map((tab) => {
                if (tab.count > 0) {
                  return (
                    <TabItem
                      isActive={tab.isActive}
                      key={tab.id}
                      id={tab.id}
                      onClick={handleTabChange}
                    >
                      {tab.name}&nbsp;({tab.count})
                    </TabItem>
                  );
                }
                return null;
              })}
            </TabsList>
          </ContentWrapper>
        </TabsNav>
      </div>
      {children}
    </>
  );
};
