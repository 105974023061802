import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";

const { colors, spaces } = xxlTheme;

const typography = {
  fontFamily: "var(--font-family-regular)",
  fontSize: 12,
  lineHeight: 1.5,
  letterSpacing: -0.1,

  tablet: {
    fontSize: 18,
    lineHeight: 1,
  },
};

export const ContentWrapper = styled.div`
  width: max-content;
  margin: 0 ${spaces.smallRegular};
  min-width: 100%;

  ${MQ("tablet")} {
    width: auto;
    margin: 0;
  }
`;

export const TabsNav = styled.nav`
  width: calc(100% + 24px);
  max-width: 100vw;
  position: relative;
  left: -12px;
  overflow-x: auto;
  overflow-y: hidden;
  margin: ${spaces.large} 0;

  ${MQ("tablet")} {
    width: 100%;
    left: 0;
    margin: ${spaces.large} auto;
  }
`;

export const TabsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: end;
  column-gap: ${spaces.large};
  min-height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.xxlLightGrey};
  width: max-content;
  min-width: 100%;

  ${MQ("tablet")} {
    width: 100%;
    max-width: calc(100% - 100px);
  }
`;

type ItemProps = {
  isActive: boolean;
};

export const TabItem = styled.li<ItemProps>(
  ({ isActive }) => css`
    ${typographyToCss(typography)};
    font-family: ${isActive && "var(--font-family-bold)"};
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: ${isActive ? "none" : "all"};
    padding: ${spaces.smallRegular};
    position: relative;
    transition: 0.1s ease;
    &:hover {
      cursor: ${isActive ? "initial" : "pointer"};
      color: ${!isActive && colors.xxlGreen};
      font-weight: ${!isActive && "700"};
    }

    ${isActive &&
    css`
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: ${colors.xxlGreen};
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    `}

    ${MQ("tablet")} {
      padding: 0 0 ${spaces.smallRegular};
    }
  `
);
