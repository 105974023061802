import React from "react";
import type { StoresDataProps } from "../SearchState";
import {
  StoreItem,
  StoreLink,
  StoreName,
  StoreInfoWrapper,
  StoreHours,
} from "./Stores.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { useTracking } from "../../../contexts/Tracking";
import { isNotEmpty } from "@xxl/common-utils";
import { CaretRight } from "@xxl/icons";

type StoreProps = {
  store: StoresDataProps;
  showOpeningHours: boolean;
};

const weekDays = [
  {
    key: 1,
    name: "Monday",
  },
  {
    key: 2,
    name: "Tuesday",
  },
  {
    key: 3,
    name: "Wednesday",
  },
  {
    key: 4,
    name: "Thursday",
  },
  {
    key: 5,
    name: "Friday",
  },
  {
    key: 6,
    name: "Saturday",
  },
  {
    key: 7,
    name: "Sunday",
  },
];

export const Store = ({ store, showOpeningHours }: StoreProps) => {
  const { t } = useTranslations();
  const tracking = useTracking();

  const date = new Date();
  const todayKey = date.getDay();
  const todayName = weekDays.find((day) => day.key === todayKey);
  const openingHours = store.openingHours.find(
    (item) => item.day === todayName?.name
  );
  const openingHoursString =
    openingHours !== undefined && openingHours.closed === false
      ? `${openingHours.opensAt as string}-${openingHours.closesAt as string}`
      : t("store.hours.closed");
  const url = store.link;

  const handleClickTracking = () => {
    isNotEmpty(store.ticket) &&
      tracking.sendClickEvent({ ticket: store.ticket });
  };

  return (
    <StoreItem onClick={handleClickTracking}>
      <StoreLink href={url}>
        <StoreInfoWrapper>
          <StoreName>{store.name}</StoreName>
          {showOpeningHours && (
            <StoreHours>
              {t("store.opentoday")} {openingHoursString}
            </StoreHours>
          )}
        </StoreInfoWrapper>
        <CaretRight />
      </StoreLink>
    </StoreItem>
  );
};
