import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";

const { colors, spaces } = xxlTheme;

const headingTypography = {
  fontFamily: "var(--font-family-bold)",
  fontSize: 15,
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

const hoursTypography = {
  fontFamily: "var(--font-family-regular)",
  fontSize: 15,
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;

  ${MQ("tablet")} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${spaces.smallRegular};
  }
`;

export const StoreItem = styled.li`
  padding: ${spaces.regular} ${spaces.smallRegular};
  border-bottom: 1px solid ${colors.xxlLightGrey};

  &:hover {
    background-color: ${colors.xxlGreen};
  }

  ${MQ("tablet")} {
    padding: ${spaces.smallRegular};
    border-bottom: none;
  }
`;

export const StoreLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    font-size: ${spaces.bigSmall};
  }
`;

export const StoreName = styled.h3`
  ${typographyToCss(headingTypography)};
  margin: 0 0 ${spaces.mini} 0;
`;

export const StoreHours = styled.p`
  ${typographyToCss(hoursTypography)};
  margin: 0;
`;

export const StoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
